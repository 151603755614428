import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import moment from 'moment';
import cn from 'classnames/bind';
import {
  faBriefcase,
  faCog,
  faEdit,
  faKey,
  faSync,
  faUserMinus,
  faUserPlus,
  faUserGear,
  faWrench,
} from '@fortawesome/pro-light-svg-icons';
import type { NewMenuItemProps } from '@odin-labs/components';
import { EllipsisDropdownButton, getFixedWidthFaIcon } from '@odin-labs/components';
import { JobsiteWorkerOrientationStatus, WorkerCreatedMethod } from 'apollo/generated/client-operations';
import { to } from 'acl';
import { AuthContext } from 'auth';
import { ObjectHistory } from 'components/objectHistory';
import { ObjectHistorySource } from 'components/objectHistory/types';
import { ensureNonEmptyItems } from 'utils';
import { convertUTCtoLocalDate, getISODateTimeToFormattedDate } from 'utils/dates';
import { JobsiteWorker } from 'containers/worker/types';
import { JobsiteWorkerAccessComponent } from './jobsiteWorkerAccessComponent';
import { JobsiteWorkerDocumentStatus } from './jobsiteWorkerDocumentStatus';
import { JobsiteWorkerMedicalStatus } from './jobsiteWorkerMedicalStatus';
import { JobsiteWorkerOnboardingStatus } from './jobsiteWorkerOnboardingStatus';
import { JobsiteWorkerSiteSafetyStatus } from './jobsiteWorkerSiteSafetyStatus';
import { JobsiteWorkerCardProps } from './types';
import { classes } from './JobsiteWorkerCard.style';

const EditIcon = getFixedWidthFaIcon({ icon: faEdit });
const BriefcaseIcon = getFixedWidthFaIcon({ icon: faBriefcase });
const WrenchIcon = getFixedWidthFaIcon({ icon: faWrench });
const KeyIcon = getFixedWidthFaIcon({ icon: faKey });
const CogIcon = getFixedWidthFaIcon({ icon: faCog });
const SyncIcon = getFixedWidthFaIcon({ icon: faSync });
const UserMinusIcon = getFixedWidthFaIcon({ icon: faUserMinus });
const UserPlusIcon = getFixedWidthFaIcon({ icon: faUserPlus });
const UserGearIcon = getFixedWidthFaIcon({ icon: faUserGear });

export function JobsiteWorkerCard(props: JobsiteWorkerCardProps): React.ReactElement {
  const {
    worker,
    jobsiteWorker,
    openChangeWorkerAccessModal,
    openDocumentExemptModal,
    openAccessDebugModal,
    openEditJobsiteClearancesModal,
    syncWorkerAccess,
    confirmSelfOnboardingWorkerEdit,
    openEditJobsiteWorkerModal,
    openEnableJobsiteWorkerSelfOnboardingModal,
    openChangeContractorModal,
    openCorrectJobsiteAssignmentModal,
    disableActions,
  } = props;

  const { currentUser: user } = React.useContext(AuthContext);

  const hasAccess = jobsiteWorker?.currentAccess.isAllowed;
  const notAllowedReasons = jobsiteWorker?.currentAccess.notAllowedReason?.split('\n').filter(Boolean);
  const { jobsite } = jobsiteWorker?.jobsiteContractor ?? {};
  const { jobsiteId, clearanceAssignmentEnabled } = jobsite ?? {};

  const getSiteAccessText = (): React.ReactElement => {
    if (hasAccess) {
      return (
        <p className={cn('odin-mb-0', classes.siteAccessText)}>
          Site access expires on{' '}
          {getISODateTimeToFormattedDate(convertUTCtoLocalDate(jobsiteWorker?.currentAccess?.endDate))}
        </p>
      );
    }
    return (
      <p className={cn('odin-mb-0', classes.siteAccessText)}>
        Site access expired on{' '}
        {getISODateTimeToFormattedDate(convertUTCtoLocalDate(jobsiteWorker?.currentAccess?.endDate))}
      </p>
    );
  };

  const isJobsiteWorkerOnboarded = !jobsiteWorker?.endDate;
  const isJobsiteWorkerBanned = jobsiteWorker?.isBanned && !jobsiteWorker?.banExpiresAt;
  const isJobsiteWorkerSuspended =
    jobsiteWorker?.isBanned && jobsiteWorker?.banExpiresAt && moment(jobsiteWorker.banExpiresAt).isAfter(moment());
  const isWorkerAllowedOnsite = isJobsiteWorkerOnboarded && !isJobsiteWorkerBanned && !isJobsiteWorkerSuspended;

  const isRevokeAccessItemVisible =
    hasAccess &&
    ((isJobsiteWorkerOnboarded && user.isAllowed(to.offboardWorkers, jobsiteId)) ||
      (!isJobsiteWorkerSuspended && user.isAllowed(to.suspendWorkers, jobsiteId)) ||
      (!isJobsiteWorkerBanned && user.isAllowed(to.banWorkers, jobsiteId)));
  const isRestoreAccessItemVisible =
    (!isJobsiteWorkerOnboarded && user.isAllowed(to.offboardWorkers, jobsiteId)) ||
    (isJobsiteWorkerSuspended && user.isAllowed(to.unsuspendWorkers, jobsiteId)) ||
    (isJobsiteWorkerBanned && user.isAllowed(to.unbanWorkers, jobsiteId));

  const isEnableJobsiteWorkerSelfOnboardingVisible =
    user.isAllowed(to.enableJobsiteWorkerSelfOnboarding) &&
    worker?.createdMethod === WorkerCreatedMethod.SelfOnboarding &&
    jobsiteWorker?.orientationStatus !== JobsiteWorkerOrientationStatus.SelfOnboarding;

  const confirmSelfOnboardingWorkerEditIfNeeded = ({
    then,
  }: {
    then: (jobsiteWorker: JobsiteWorker) => void;
  }): void => {
    if (jobsiteWorker.orientationStatus === JobsiteWorkerOrientationStatus.SelfOnboarding) {
      confirmSelfOnboardingWorkerEdit({ then: () => then(jobsiteWorker) });
    } else {
      then(jobsiteWorker);
    }
  };

  const menuItems = ensureNonEmptyItems<NewMenuItemProps>([
    {
      icon: EditIcon,
      text: 'Edit Worker Data',
      theme: 'secondary',
      onClick: (): void => confirmSelfOnboardingWorkerEditIfNeeded({ then: openEditJobsiteWorkerModal }),
    },
    user.isAllowed(to.changeContractor) && {
      icon: WrenchIcon,
      text: 'Change Contractor',
      theme: 'secondary',
      onClick: (): void => confirmSelfOnboardingWorkerEditIfNeeded({ then: openChangeContractorModal }),
    },
    user.isAllowed(to.correctJobsite) && {
      icon: BriefcaseIcon,
      text: 'Correct Jobsite',
      theme: 'secondary',
      onClick: (): void => confirmSelfOnboardingWorkerEditIfNeeded({ then: openCorrectJobsiteAssignmentModal }),
    },
    user.isAllowed(to.editJobsiteAccess) &&
      clearanceAssignmentEnabled && {
        icon: KeyIcon,
        text: 'Edit Jobsite Access',
        theme: 'secondary',
        onClick: (): void => openEditJobsiteClearancesModal(jobsiteWorker),
      },
    user.isAllowed(to.debugWorkerAccess) && {
      icon: CogIcon,
      text: 'Debug Worker Access',
      theme: 'secondary',
      onClick: (): void => openAccessDebugModal(jobsiteWorker),
    },
    {
      icon: SyncIcon,
      text: 'Sync Worker Access',
      theme: 'secondary',
      onClick: syncWorkerAccess,
    },
    isWorkerAllowedOnsite &&
      isRevokeAccessItemVisible && {
        icon: UserMinusIcon,
        text: 'Revoke Access',
        theme: 'secondary',
        onClick: (): void => openChangeWorkerAccessModal(jobsiteWorker),
      },
    !isWorkerAllowedOnsite &&
      isRestoreAccessItemVisible && {
        icon: UserPlusIcon,
        text: 'Restore Access',
        theme: 'secondary',
        onClick: (): void => openChangeWorkerAccessModal(jobsiteWorker),
      },
    isEnableJobsiteWorkerSelfOnboardingVisible && {
      icon: UserGearIcon,
      text: 'Return to self onboarding',
      theme: 'secondary',
      onClick: (): void => openEnableJobsiteWorkerSelfOnboardingModal(jobsiteWorker),
    },
  ]);

  const { contractorId, organization } = jobsiteWorker?.jobsiteContractor?.contractor ?? {};
  const contractorName = organization?.name;
  const jobsiteName = jobsite?.name;

  const trade = worker?.trade || 'N/A';
  const tradeClass = worker?.jobTitle || 'N/A';
  const stickerNumber = jobsiteWorker?.stickerNumber || 'N/A';
  const payrollIdText = jobsiteWorker?.payrollId || 'N/A';
  const clearancesText = jobsiteWorker?.clearanceAssignments?.edges?.map(({ node }) => node.clearance.name)?.join(', ');
  const onboardDate = getISODateTimeToFormattedDate(jobsiteWorker?.startDate) || 'N/A';
  const offboardDate = getISODateTimeToFormattedDate(jobsiteWorker?.endDate) || 'N/A';
  const escortPrivileges = jobsiteWorker?.isAntipassbackExempt ? 'Yes' : 'No';
  const inactivityExempt = jobsiteWorker?.isExemptFromSwipeInactivity ? 'Yes' : 'No';
  const skipRefresherTraining = jobsiteWorker?.isExemptFromSiteSpecificRefresher ? 'Yes' : 'No';

  const jobsiteLink = <Link to={`/jobsite/${jobsiteId}`}>{jobsiteName}</Link>;
  const contractorLink = <Link to={`/contractor/${contractorId}`}>{contractorName}</Link>;

  return (
    <Card>
      <CardBody>
        <div className="odin-flex">
          <div
            className={`odin-mb-5 odin-flex-grow odin-grid odin-grid-cols-1 
          sm:odin-grid-cols-3 sm:odin-gap-x-3`}
          >
            <div>
              <h1 className="odin-mb-0 odin-mt-1">
                <span>{jobsiteLink}</span>
              </h1>
              <h3 className="odin-my-1.5">
                <span>{contractorLink}</span>
              </h3>
              <JobsiteWorkerAccessComponent jobsiteWorker={jobsiteWorker} type="badge" showEndDate />
              {getSiteAccessText()}
              <ObjectHistory
                className={cn(classes.siteAccessText, 'align-self-center d-inline-block')}
                object={jobsiteWorker}
                source={ObjectHistorySource.JobsiteWorker}
              />
            </div>
            <div className="odin-mt-1.5">
              <div className={classes.jobsiteWorkerField}>
                <div className={classes.jobsiteWorkerFieldLabel}>Trade</div>
                <div className={classes.jobsiteWorkerFieldValue}>{trade}</div>
              </div>
              <div className={classes.jobsiteWorkerField}>
                <div className={classes.jobsiteWorkerFieldLabel}>Trade class</div>
                <div className={classes.jobsiteWorkerFieldValue}>{tradeClass}</div>
              </div>
              <div className={classes.jobsiteWorkerField}>
                <div className={classes.jobsiteWorkerFieldLabel}>Sticker number</div>
                <div className={classes.jobsiteWorkerFieldValue}>{stickerNumber}</div>
              </div>
              <div className={classes.jobsiteWorkerField}>
                <div className={classes.jobsiteWorkerFieldLabel}>Payroll ID</div>
                <div className={classes.jobsiteWorkerFieldValue}>{payrollIdText}</div>
              </div>
              {user.isAllowed(to.editJobsiteAccess) && clearanceAssignmentEnabled && (
                <div className={classes.jobsiteWorkerField}>
                  <div className={classes.jobsiteWorkerFieldLabel}>Clearances</div>
                  <div className={classes.jobsiteWorkerFieldValue}>{clearancesText}</div>
                </div>
              )}
            </div>
            <div className="odin-mt-1.5">
              <div className={classes.jobsiteWorkerField}>
                <div className={classes.jobsiteWorkerFieldLabel}>Onboard date</div>
                <div className={classes.jobsiteWorkerFieldValue}>{onboardDate}</div>
              </div>
              <div className={classes.jobsiteWorkerField}>
                <div className={classes.jobsiteWorkerFieldLabel}>Offboard date</div>
                <div className={classes.jobsiteWorkerFieldValue}>{offboardDate}</div>
              </div>
              <div className={classes.jobsiteWorkerField}>
                <div className={classes.jobsiteWorkerFieldLabel}>Escort privileges</div>
                <div className={classes.jobsiteWorkerFieldValue}>{escortPrivileges}</div>
              </div>
              <div className={classes.jobsiteWorkerField}>
                <div className={classes.jobsiteWorkerFieldLabel}>Inactivity exempt</div>
                <div className={classes.jobsiteWorkerFieldValue}>{inactivityExempt}</div>
              </div>
              <div className={classes.jobsiteWorkerField}>
                <div className={classes.jobsiteWorkerFieldLabel}>Skip refresher training</div>
                <div className={classes.jobsiteWorkerFieldValue}>{skipRefresherTraining}</div>
              </div>
            </div>
          </div>
          {!disableActions && (
            <div>
              <EllipsisDropdownButton menuItems={menuItems} popupWidthFitContent />
            </div>
          )}
        </div>

        {notAllowedReasons?.map((reason) => {
          return (
            <Row key={reason}>
              <Col lg="12">
                <div className={classes.butterBar}>
                  <span className="odin-flex odin-pr-3">
                    <i className="odin-text-odin-primary fal fa-info-circle" />
                  </span>
                  <p className="odin-mb-0 odin-text-sm">
                    <span>{reason}</span>
                  </p>
                </div>
              </Col>
            </Row>
          );
        })}
      </CardBody>
      <CardFooter className="p-4">
        <div className="odin-flex">
          <div className="odin-w-full sm:odin-w-4/12">
            <p className={classes.footerStatus}>Onboarding status</p>
            <JobsiteWorkerOnboardingStatus
              jobsiteWorker={jobsiteWorker}
              confirmSelfOnboardingWorkerEdit={confirmSelfOnboardingWorkerEdit}
              openDocumentExemptModal={openDocumentExemptModal}
              disableActions={disableActions}
              showLabel
            />
          </div>
          <div className="odin-w-full sm:odin-w-8/12">
            <div className="odin-grid odin-grid-cols-1 sm:odin-grid-cols-3 sm:odin-gap-x-3">
              <div>
                <p className={classes.footerStatus}>Site Orientation status</p>
                <JobsiteWorkerSiteSafetyStatus
                  jobsiteWorker={jobsiteWorker}
                  confirmSelfOnboardingWorkerEdit={confirmSelfOnboardingWorkerEdit}
                  disableActions={disableActions}
                  showLabel
                />
              </div>
              <div>
                <p className={classes.footerStatus}>Document status</p>
                <JobsiteWorkerDocumentStatus
                  worker={worker}
                  jobsiteWorker={jobsiteWorker}
                  confirmSelfOnboardingWorkerEdit={confirmSelfOnboardingWorkerEdit}
                  disableActions={disableActions}
                  showLabel
                />
              </div>
              <div>
                <p className={classes.footerStatus}>Medical status</p>
                <JobsiteWorkerMedicalStatus
                  worker={worker}
                  jobsiteWorker={jobsiteWorker}
                  confirmSelfOnboardingWorkerEdit={confirmSelfOnboardingWorkerEdit}
                  disableActions={disableActions}
                />
              </div>
            </div>
          </div>
        </div>
      </CardFooter>
    </Card>
  );
}
